.article-information {
    position: absolute;
    width: 60%;
    background: $white;
    padding: 0 10rem;
    bottom: -8rem;
    border-radius: 15px 15px 0 0;
    @media screen and ( max-width: 767px ) {
        padding: 0 2rem;
        width: 90%; } }

article {
    &.news {
        padding: 0 10rem;
        @media screen and ( max-width: 767px ) {
            padding: 0 2rem; } }
    p {
        margin-bottom: 2rem; } }
.fraud-tabs {
    padding: 0 10rem;
    margin-bottom: 1rem;
    @media screen and ( max-width: 767px ) {
        padding: 0rem; } }
.ui-accordion-content {
    background-color: #F3F5F7 !important;
    margin-top: -1rem;
    padding: 2rem 4rem !important;
    @media screen and ( max-width: 567px ) {
        padding: 2rem !important; } }
.ui-accordion-header {
    margin-top: 1rem;
    .fa-angle-down:before {
        font-size: 3rem !important; } }

