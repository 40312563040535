@media screen and ( max-width: 567px ) {
    .title-404 {
        font-size: 3rem !important; } }
div.error-404 {
    background: $light;
    overflow: hidden;
    position: relative;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 0 0 15px;
    &:before {
        content: url('../img/illustrations/shape-globe-yellow.svg');
        position: absolute;
        right: 0;
        z-index: 1; }
    @media screen and ( max-width: 567px ) {
        height: 20vh; }
    span {
        position: relative;
        font-size: 20rem;
        color: $primary;
        font-weight: bold;
        z-index: 10;
        @media screen and ( max-width: 1024px ) {
            font-size: 16rem; }
        @media screen and ( max-width: 992px ) {
            font-size: 10rem; }
        @media screen and ( max-width: 567px ) {
            font-size: 6rem; } } }
.text-404 {
    margin-left: 18rem;
    @media screen and ( max-width: 1024px ) {
        margin-left: 0rem; } }
