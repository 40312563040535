.cookies {
    width: 100%;
    background: transparent;
    bottom: 0rem;
    left: 0;
    max-width: 872px !important;
    a:nth-child(2), p {
        color: white !important;
        text-decoration: none !important; }
    @media screen and ( max-width: 567px ) {
        p {
            display: none; } }
    a.acceptcookies {
        margin: 0 !important; } }
#open-cookie {
    background: #D12128;
    border-radius: 0 15px 0 0;
    @media screen and ( max-width: 567px ) {
        border-radius: unset;
        .btn-container {
            display: flex;
            .bg-white {
                @media screen and ( max-width: 567px ) {
                    margin-right: 2rem; } } } } }
#close-cookie {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 30px;
    cursor: pointer;
    z-index: 111111; }
.cookiealert {
    color: white;
    background: transparent !important; }
.cookiealert.show {
    padding: 1rem;
    transform: translateY(5%) !important; }

.popup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $light;
    width: 75vw !important;
    position: fixed;
    left: 150%;
    bottom: 30%;
    top: 25%;
    transition: all 1s;
    transform: translateY(0%) translateX(250%) !important;
    z-index: 200000;
    @media screen and ( max-width: 992px ) {
        flex-direction: column;
        top: 30%;
        bottom: 30%;
        .img-container {
            width: 100% !important;
            height: 65% !important; }
        .text-container {
            width: 100%  !important; } }
    @media screen and ( max-width: 567px ) {
        top: 25%;
        bottom: auto;
        .img-container {
            width: 100% !important;
            height: 40% !important; } }
    .img-container {
        width: 60%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    .text-container {
        width: 40%;
        padding: 2rem;
        p {
            color: $black;
            font-size: 1.6rem; } }
    .acceptpopup {
        position: absolute;
        right: 1rem;
        top: 0rem;
        @media screen and ( max-width: 768px ) {
            background-color: $light;
            right: 0rem;
            top: 0rem;
            width: 3.5rem;
            text-align: center;
            opacity: 1; } }
    a {
        span {
            font-size: 3.5rem;
            color: $red !important; }
        &:hover {
            text-decoration: none; } } }
.popup.show {
    position: fixed;
    top: 25%;
    bottom: 30%;
    left: 50% !important;
    transform: translateY(0%) translateX(-50%) !important;
    transition: all 1s;
    @media screen and ( max-width: 1024px ) {
        top: 40%;
        bottom: 40%;
        width: 90vw !important; }
    @media screen and ( max-width: 992px ) {
        flex-direction: column;
        top: 30%;
        bottom: 30%;
        .img-container {
            width: 100%;
            height: 65%; }
        .text-container {
            width: 100% !important; } }

    @media screen and ( max-width: 567px ) {
        top: 25%;
        bottom: auto;
        .img-container {
            height: 40% !important; }
        .text-container {
            p {
                font-size: 1.4rem; } } } }
