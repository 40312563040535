html, body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 62.5%;
  line-height: 1; }

* {
  font-size: 1.5rem;
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

button {
  &:focus {
    outline: none !important; } }

ul {
  list-style-type: none;
  padding: 0; }

a {
  cursor: pointer; }

// Prevent content selecting
// *
//   -webkit-touch-callout: none
//   -webkit-user-select: none
//   -khtml-user-select: none
//   -moz-user-select: none
//   -ms-user-select: none
//   user-select: none
