.hero {
    .hero-siema-slider {
        @media screen and ( min-width: 567px ) {
            border-radius: 0 0 50px 50px; } }
    .siema {
        .slide {
            position: relative;
            height: 65vh;
            @media screen and ( max-width: 1024px ) {
                height: 35vh; }
            @media screen and ( max-width: 567px ) {
                height: unset; }
            .slider-content {
                position: absolute;
                bottom: 8rem;
                color: $white;
                margin-left: 14rem;
                width: 49rem;
                height: auto;
                z-index: 999;
                @media screen and ( max-width: 1300px) {
                    width: auto !important;
                    margin-left: 5rem !important; }
                @media screen and ( max-width: 567px) {
                    width: auto !important;
                    margin-left: 3rem !important; }
                @media screen and ( max-width: 958px) {
                    bottom: 4rem !important;
                    .h1 {
                        font-size: 3.5rem !important; } }
                @media screen and ( max-width: 767px) {
                    bottom: 6rem !important;
                    .h1 {
                        font-size: 3rem !important;
                        margin-bottom: 0 !important; } }
                @media screen and ( max-width: 356px) {
                    .h1 {
                        font-size: 2.5rem !important; } }
                p {
                    color: $white; } } }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: none;
            @media screen and ( min-width: 600px ) {
                border-radius: 0 0 50px 50px; } } }

    .dark-layout.show {
        &:before {
            background: linear-gradient(transparent, rgba(0,0,0,0.8));
            @media screen and ( min-width: 600px ) {
                border-radius: 0 0 50px 50px; } }
        img {
            display: block; } } }
.home-layout {
    .hero .container {
        position: relative;
        &:before {
            content: url(../img/illustrations/hero-yellow-shape.svg);
            position: absolute;
            left: -9rem;
            top: 1rem;
            @media screen and ( max-width: 767px ) {
                display: none; } }
        &:after {
            content: url(../img/illustrations/hero-red-shape.svg);
            position: absolute;
            right: -8.7rem;
            bottom: 3rem;
            z-index: -1;
            @media screen and ( max-width: 767px ) {
                display: none; } } } }
.controllers {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    z-index: 999;
    margin: 0 2rem 1rem 0;
    li a {
        border-radius: 10px; }
    &.hero-controllers {
        right: unset;
        bottom: 2.5rem;
        left: 14rem;
        background-color: #343431;
        border-radius: 15px;
        @media screen and ( max-width: 767px ) {
            left: unset;
            right: 1rem;
            bottom: 1.5rem; } }
    ul li {
        float: left;
        margin-right: .5rem;
        &:last-child {
            margin-right: 0; }
        a {
            display: block;
            width: 2.7rem;
            border-radius: 10px;
            height: .7rem;
            background: #A8A8A8;
            opacity: .6;
            &.active {
                opacity: 1;
                background: $primary; } } } }
.mobile-heading-text {
    background: $primary;
    margin: -0.5rem 0 0 2rem;
    color: $white;
    padding: 2rem;
    z-index: 999;
    width: 100%;
    h2 {
        font-size: 3rem; } }
@media screen and ( max-width: 767px ) {
    #services {
        padding: 4rem 0 !important; } }

section.hero {
    .aside-box {
        display: block;
        position: relative;
        &.primary {
            height: 60%; }
        &.secondary {
            height: 40%;
            padding-top: 1rem; }
        .holder {
            .info {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding-top: 2rem;
                z-index: 99;
                &:before {
                    content: url('../img/elements/white-arrow.svg');
                    width: 30%;
                    height: 100%;
                    background: $primary;
                    line-height: 6.8;
                    padding-right: 8rem;
                    text-align: right;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: -10;
                    transition: all .3s;
                    @media screen and ( max-width:  1166px) {
                        content: none !important; } } } }


        .offices {
            height: 100%;
            background: url('../img/globals/bg-map.webp') no-repeat center;
            background-size: cover;
            background-color: #222222;
            &:hover .info:before {
                width: 80%;
                padding-right: 4rem;
                transition: all .3s; } }

        .become-a-partner {
            height: 100%;
            background: url('../img/images/photo-of-people-doing-handshakes.webp')no-repeat center;
            background-size: cover;
            background-position: top;
            &:hover .info:before {
                width: 80%;
                padding-right: 4rem;
                transition: all .3s; } } } }
.latest-news {
    .info {
        border-radius: 0 15px 15px 0; } }
.overflow-holder {
    overflow-y: hidden;
    & > div {
        width: 100%;
        justify-content: space-between;
        display: flex;
        @media screen and (max-width: 576px) {
            width: max-content;
            & > div {
                margin: 0 1.2rem 1rem 0; } } } }
.overflow-holder::-webkit-scrollbar {
    height: 0.4rem;
    overflow-y: hidden; }
.overflow-holder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2); }
.overflow-holder::-webkit-scrollbar-thumb {
    background-color: $primary; }

.grid-container {
    display: grid !important;
    grid-template-columns: auto auto 40%;
    grid-gap: 1rem;
    .service {
        width: 21rem;
        background: #F3F5F7;
        text-align: center;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: end;
        border-radius: 15px;
        &:first-child {
            margin-left: 0; }
        &:last-child {
            margin-right: 0;
            @media screen and ( max-width:  767px) {
                img {
                    height: 10rem !important; } } }
        img {
            width: 70%;
            padding-bottom: 2rem;
            margin: 0 auto; }
        &:last-child {
            grid-row: 1 / 3;
            grid-column: 3 / 3;
            padding-bottom: 4rem;
            img {
                width: 100%; } } } }
#services {
    .overflow-holder {
        overflow: hidden;
        @media screen and ( max-width: 768px) {
            display: flex;
            justify-content: space-between;
            overflow: scroll;
            .grid-container {
                display: flex !important; } } } }
#calculator {
    @media screen and (max-width: 768px) {
        padding-top: 5rem; } }
.calculator {
    display: flex;
    justify-content: space-between;
    background: $light;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    .content {
        padding: 5rem;
        width: 50%;
        z-index: 99;
        @media screen and (max-width: 768px) {
            padding: 2rem; } }
    &:before {
        content: url('../img/illustrations/shape-2.svg');
        position: absolute;
        left: 0;
        top: 0; }
    @media screen and (max-width: 768px) {
        display: grid;
        .content {
            width: 100%; }
        div .justify-content-between {
            justify-content: end !important; }
        h2 {
            font-size: 3rem !important;
            width: 80vw;
            span {
                font-size: 3rem !important; } }
        p {
            width: 80vw; }
        .cash-express-select, #final_sum {
            width: 80vw; }
        #sum, {
            width: 40vw !important; }
        .currency-container {
            width: 36vw !important; } }
    .calculator-cont {
        background: $red;
        display: flex;
        align-items: center;
        width: 428px;
        .calc {
            padding: 5rem;
            @media screen and (max-width: 768px) {
                padding: 2rem; }
            p {
                color: $white; } }
        .taxes {
            height: 28px;
            display: flex;
            span {
                padding-left: 1rem; } }
        input:focus,
        .select-selected:focus {
            border: 1px solid $white; } } }
.benefit {
    width: 36rem;
    background: $light;
    border-radius: 15px;
    padding: 2rem 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:nth-child(2) {
        margin: 0 1rem; }
    &.white-shape:before {
        content: url('../img/illustrations/shape-benefit-white.svg');
        position: absolute;
        right: 0; }
    &.red-shape:before {
        content: url('../img/illustrations/shape-benefit-red.svg');
        position: absolute;
        right: 0; }
    @media screen and ( max-width: 768px) {
        padding: 1rem;
        &::before {
            content: none !important; } }
    img {
        width: 70%;
        height: 200px;
        margin-bottom: 1rem;
        @media screen and ( max-width:  768px) {
            width: 100% !important; } }
    .content {
        width: 80%;
        position: relative; } }
.special-benefit:hover {
    text-decoration: transparent !important;
    .arrow {
        width: 30%;
        transition: all .3s;
        p {
            visibility: visible; } } }
.special-benefit > div {
    position: relative;
    color: $white;
    padding: 2rem;
    h3,p {
        position: relative;
        z-index: 10; }
    &:hover {
 }        // text-decoration: none !important
    @media screen and (min-width: 768px) {
        height: 165px; }
    .arrow {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        width: 10%;
        overflow: hidden;
        align-items: center;
        background: $primary;
        padding: 2rem;
        transition: all .3s;
        z-index: 10;
        p {
            visibility: hidden;
            white-space: pre; }
        &:before {
            content: url(../img/elements/white-arrow.svg);
            padding-right: 2rem;
            position: absolute;
            height: 100%;
            right: 0;
            display: flex;
            align-items: center;
            background: $primary; } }
    &:hover {
        text-decoration: none !important; } }
.benefits .col-md-6:nth-child(2) .special-benefit::before {
    border-radius: 0 0 15px 0; }
.special-benefit {
    &::before {
        content: "";
        display: block;
        border-radius: 15px;
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
        z-index: 1; } }
.special-benefit .security {
    border-radius: 15px 0 15px 15px; }
.special-benefit .become-our-agent-special-benefit {
    border-radius: 0 15px 15px 0;
    .arrow {
        border-radius: 0 0 15px 0; } }
.become-our-agent-special-benefit {
    background-position: top;
    background-size: cover; }

#new-offices {
    padding-bottom: 0;
    @media (max-width: 768px) {
        margin: 0 1rem;
        .img-container {
            margin: 0 1rem; } }
    .one-side {
        position: relative;
        h2 span {
            color: $red;
            font-size: 4.5rem; }
        .siema-new-offices {
            .content-container {
                display: flex;
                position: relative;
                .info {
                    background: $red;
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 4.3rem;
                    z-index: 1;
                    top: 2rem;
                    @media (max-width: 768px) {
                        right: 2.3rem; }
                    &:hover + .img-container .slogan-container {
                        display: block; } }
                .img-container {
                    position: relative;
                    width: 280px;
                    height: 370px;
                    border-radius: 15px;
                    overflow: hidden;
                    position: relative;
                    margin-right: 1rem;
                    @media (max-width: 768px) {
                        width: 100%; }
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: linear-gradient(transparent, rgba(0, 0, 0, 0.8)); }
                    img {
                        height: 100%; }
                    .new-city {
                        position: absolute;
                        bottom: 1rem;
                        left: 2rem;
                        color: $white;
                        font-size: 3rem;
                        font-weight: bold;
                        &::before {
                            margin-right: 1rem;
                            content: url(../img/elements/location.svg); } } }
                .slogan-container {
                    background-color: $red;
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    height: 80%;
                    color: $white;
                    padding: 2.3rem;
                    border-radius: 15px;
                    display: none;
                    .new-city {
                        position: unset; }
                    h4 {
                        font-size: 2.5rem; }
                    span {
                        font-size: 1.6rem;
                        color: $white; }
                    > span {
                        display: block;
                        margin-bottom: 1.6rem; }
                    > div {
                        display: flex;
                        align-items: baseline;
                        p {
                            margin-right: 0.5rem;
                            color: $white;
                            strong {
                                font-weight: 800; } }
                        strong {
                            font-size: 1.6rem !important;
                            p {
                                font-weight: 800; } } } } } }
        .controllers-new-offices {
            display: flex;
            justify-content: center;
            margin-top: 3rem;
            ul {
                display: flex;
                margin-right: 15rem;
                @media (max-width: 768px) {
                    margin-right: 0; }
                li {
                    margin-right: 0.5rem;
                    a {
                        border-radius: 10px;
                        display: block;
                        width: 2.7rem;
                        height: .7rem;
                        background: #A8A8A8;
                        opacity: .6; }
                    a.active {
                        opacity: 1;
                        background: $red; } } } } } }
@media (min-width:768px) {
    .one-side {
        margin-right: 0;
        margin-left: calc(50vw - 375px); } }
@media (min-width:992px) {
    .one-side {
        margin-right: 0;
        margin-left: calc(50vw - 485px); } }
@media (min-width:1200px) {
    .one-side {
        margin-right: 0;
        margin-left: calc(50vw - 570px); } }
@media screen and ( max-width: 576px ) {
    #new-offices {
        .container {
            .controllers-new-offices {
                margin-bottom: -1rem;
                .index {
                    display: none; } }
            .siema-new-offices {
                .content-container {
                    flex-direction: column;
                    .img-container {
                        width: 100%;
                        height: 30rem;
                        h2 {
                            font-size: 2.5rem;
                            span {
                                font-size: 2.5rem; } }
                        > span {
                            font-size: 1.6rem; }
                        > div {
                            p {
                                font-size: 1.4rem; }
                            strong {
                                font-size: 1.4rem !important; } } } } } } } }
