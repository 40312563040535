.message {
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: -100%;
  justify-content: space-between;
  padding-right: 8rem;
  max-width: 564px;
  background: #F3F5F7;
  transition: all 2s;
  z-index: 99999;
  background-image: url('../img/elements/success.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  .content {
    padding: 2rem; }
  &.visible {
    right: 0;
    transition: all 2s; } }

#slide-up-success {
  width: 100%;
  position: relative; }


// MediaQ
@media screen and ( max-width:  564px) {
  .message {
    right: -170%; } }

