@mixin timeline_bar($color) {
    position: relative;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: .5rem;
        background: $color;
        position: absolute;
        top: 40%;
        z-index: -10; }
    a {
        display: block;
        background: $light;
        padding: .6rem 1rem;
        margin: .4rem 1rem;
        transition: all .1s;
        border-radius: 10px;
        &:hover,
        &.active {
            background: $color;
            color: $white;
            transition: all .1s; } }
    @media screen and (max-width: 600px) {
        display: flow-root;
        &:before {
            content: none; }
        a {
            display: inline-block; } } }
@mixin timeline_entry($color, $year-opacity) {
    background-color: $light;
    padding: 1.5rem;
    padding-top: 5.5rem;
    margin-right: 1rem;
    span.year {
        font-size: 8.6rem;
        position: absolute;
        font-weight: bold;
        color: $color;
        opacity: $year-opacity;
        top: 0;
        line-height: 1; }
    p {
        position: relative; } }

.achievements_timeline {
    .timeline_bar {
        @include timeline_bar($primary); }

    .timeline_entry {
        border-radius: 15px;
        @include timeline_entry($black, 0.1); } }

.history_timeline {
    .timeline_bar {
        @include timeline_bar($primary); }
    .timeline_entry {
        @include timeline_entry($yellow, 0.3); } }

@media screen and (min-width: 1024px) {
    .siema {
        overflow: visible !important;
        clip-path: inset(-100vw -100vw -100vw 0px) !important; } }
