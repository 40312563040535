footer {
    padding: 7rem 0 2rem;
    @media screen and (max-width: 576px) {
        padding: 0 0 2rem; }
    h5,h6 {
        margin-bottom: 1.5rem; }
    ul {
        padding: 0;
        li {
            margin-bottom: 1rem; } }
    a {
        font-weight: lighter;
        color: $font-color;
        &:hover {
            text-decoration: none; } }
    .logo {
        img {
            width: 10rem; } }
    .credits {
        height: 100%;
        display: flex;
        align-items: flex-end; }
    #year {
        font-size: 1.2rem; } }

// back-to-top

#scroll img {
    transition: .3s ease-in-out;
    height: 5rem;
    &:hover {
        opacity: .9; } }
.back-to-top {
    position: fixed;
    right: 2rem;
    bottom: 3.5rem;
    z-index: 999;
    border-radius: 10px; }
