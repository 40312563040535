h1,h2,h3,h4,h5,h6 {
    font-family: $font-primary; }
a,p,span {
    font-family: $font-primary; }

h1 {
  font-size: $size-1;
  font-weight: 700 !important;
  line-height: 1;
  margin: 30px 0 20px 0 !important; }

h2 {
  font-size: $size-2;
  font-weight: 700 !important;
  line-height: 1.1;
  margin: 0 0 1.5rem !important; }

h3 {
  font-size: $size-3;
  font-weight: 700 !important;
  line-height: 1.2;
  margin: 20px 0 10px 0; }

h4 {
  font-size: $size-4;
  font-weight: 700 !important;
  line-height: 1.3;
  margin: 20px 0 10px 0;
  @media screen and ( max-width: 420px ) {
    font-size: 2.1rem !important; } }

h5 {
  font-size: $size-5;
  font-weight: 700 !important;
  line-height: 1.2;
  margin: 10px 0; }

h6 {
  font-size: $size-6;
  font-weight: 700 !important;
  line-height: 1.05;
  margin: 5px 0; }

p {
  font-size: $font-size;
  font-weight: lighter;
  color: $font-color;
  margin: 5px 0; }

a {
  color: inherit;
  line-height: 1;

  &:hover {
    color: inherit;
    text-decoration: none; } }

.text-yellow {
    color: $yellow; }
