.find-offices {
    position: relative; }
#find-offices {
    position: absolute;
    z-index: 111;
    left: 9%;
    top: 38rem;
    background: $white;
    width: 380px;
    border-radius: 15px;
    padding: 2.3rem;
    p {
        font-size: 2rem; }
    @media screen and ( max-width: 780px ) {
        width: 100%;
        position: unset;
        margin-top: -2rem;
        margin-bottom: 2rem; } }
.find-offices-section {
    position: absolute;
    background-color: $primary;
    margin-bottom: 3rem;
    z-index: 121;
    width: 380px;
    padding: 2.5rem 3.2rem;
    left: 9%;
    top: 8rem;
    border-radius: 15px;
    @media screen and ( max-width: 780px ) {
        width: 100%;
        position: unset; }
    h1 {
        font-size: 1.9rem !important;
        margin-top: 0 !important;
        line-height: 1.2 !important; }
    .button-yellow {
        width: 100%;
        height: 5rem; }
    .form-group {
        position: relative;
        .fa-search {
            position: absolute;
            right: 2rem;
            top: 2rem; }
        input {
            border-radius: 10px;
            margin-bottom: 1rem;
            padding: 1.6rem 2rem 0.8rem 2rem;
            margin: 0;
            &:focus ~ label {
                top: 1.2rem;
                left: 2rem; } } }

    &::before {
        content: url(../img/illustrations/shape-offices.svg);
        position: absolute;
        background-size: 12rem auto;
        left: 0;
        bottom: 0;
        background-repeat: no-repeat;
        height: 100%;
        width: 14rem;
        z-index: -1; }
    .find-offices-slogan {
        font-size: 2rem;
        color: $white;
        font-weight: 300 !important;
        line-height: 1.5;
        span {
            font-weight: bold !important;
            font-size: 2rem; } } }

@media screen and ( max-width: 1024px ) {
    .find-offices-slogan {
        margin-left: 10rem;
        h1 {
            font-size: 2rem !important;
            span {
                font-size: 2rem !important; } } }
    .search-btn {
        flex-direction: column !important;
        .form-group {
            margin-bottom: 2rem !important;
            margin-right: 0 !important;
            width: 20rem; } } }
@media screen and ( max-width: 768px ) {
    .find-offices-section {
        height: auto;
        .find-offices-slogan {
            margin-left: 6rem;
            margin-top: 2rem;
            p {
                width: 60%; } }
        .search-btn {
            margin-bottom: 4rem;
            align-items: flex-start;
            margin-left: 12rem; }
        .button-primary:not(:hover) {
            margin-top: 0 !important; } } }
@media screen and ( max-width: 567px ) {
    .find-offices-section {
        height: auto;
        margin: 0 0 3rem 0;
        overflow: visible;
        &::before {
            display: none; }
        &::after {
            display: none; }
        .find-offices-slogan {
            margin: 1rem 0;
            p {
                font-size: 1.8rem !important;
                width: 90%;
                span {
                    font-size: 1.8rem !important; } } } }
    .search-btn {
        flex-direction: column;
        align-items: flex-start !important;
        margin-bottom: 4rem;
        margin-left: 0rem !important;
        .form-group {
            width: 100%;
            margin-bottom: 2rem !important; }
        .primary-button {
            margin-bottom: 2rem; } }
    #offices {
        h2.h3 {
            font-size: 2.5rem !important; } } }
.find-offices {
    display: flex;
    height: 70vh;
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 10rem; }
    div.city-map {
        width: 100%; }
    @media screen and (max-width: 992px) {
        height: auto;
        display: block;
        .content {
            display: block;
            padding: 2rem; }
        div.city-map {
            height: 400px; } } }

.box-office {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    background: $light;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid $primary; }

    h3 {
        font-size: $size-5;
        margin-bottom: 1.5rem; }
    a {
        color: $grey; }
    .status {
        display: block;
        margin: 1.2rem 0;
        font-weight: bold; }
    @media screen and (max-width: 992px) {
        margin-bottom: 2rem; } }

.matchingCities {
    max-height: 200px;
    background-color: #ffffff;
    overflow: scroll;
    margin-bottom: 1rem;
    overflow-x: hidden;
    width: 100%;
    position: absolute;
    border: .5px solid #F3F5F7;
    z-index: 99;
    cursor: pointer;
    border-radius: 1rem;
    ul {
        li {
            padding: 5px 20px;
            border: .5px solid #F3F5F7; } } }

.icon-rotate {
    i {
        font-size: 25px;
        padding: 0 1rem; } }

.rotate {
    transform: rotateZ(180deg) !important; }
.rotate2 {
     transform: rotateZ(360deg) !important; }
