//** Input range styles
$track-color: $primary !default;
$thumb-color: $primary !default;

$thumb-radius: 12px !default;
$thumb-height: 24px !default;
$thumb-width: 24px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 1px !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 8px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 0px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

//** Select styles
/* The container must be positioned relative: */

.cash-express-select {
  position: relative;
  font-family: Arial;

  select {
    display: none; } }

.select-selected {
  background-color: $white;

  &:after {
    position: absolute;
    content: "";
    top: auto;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: $primary transparent transparent transparent; }

  &.select-arrow-active:after {
    border-color: transparent transparent $primary transparent;
    top: auto; } }

/* Style the arrow inside the select element: */

/* Point the arrow upwards when the select box is open (active): */

/* style the items (options), including the selected item: */

.select-items div, .select-selected {
  color: $black;
  padding: 20px 30px 8px 15px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer; }
.select-items div {
  padding: .8rem 2rem; }

/* Style items (options): */

.select-items {
  position: absolute;
  background-color: $white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 0 0 15px 15px;
  margin-top: -1rem;
  div:last-child {
    border-radius: 0 0 15px 15px; } }

/* Hide the items when the select box is closed: */

.select-hide {
  display: none; }

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1); }

//** Text, number styles
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
    outline: none;
    border: 1px #fff solid;
    font-weight: 500;
    padding: .8rem 2rem;
    padding-top: 2rem;
    &:focus {
        border: 1px solid $primary; } }
input::placeholder,
textarea::placeholder {
    color: $grey-light;
    font-weight: 500; }
label.upload-file {
  cursor: pointer !important; }


// Label Checkbox
label.checkbox {
    padding-left: 2.5rem;
    position: relative;
    cursor: pointer;
    &:before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid black;
        position: absolute;
        left: 0;
        border-radius: 2px;
        top: 5px; }
    &:after {
        content: "";
        display: none;
        position: absolute;
        width: 9px;
        height: 9px;
        background: $primary;
        top: 8px;
        left: 3px; } }
input[type='checkbox']:checked + label:after {
    display: block; }

.form-group {
    position: relative;
    input, select, .select-selected, textarea {
      border-radius: 10px; }
    label:not(.checkbox) {
        position: absolute;
        top: 50%;
        left: 2rem;
        transform: translateY(-50%);
        color: $grey-light;
        cursor: initial;
        transition: all .15s;
        line-height: 1;
        pointer-events: none; }
    textarea ~ label:not(.checkbox) {
      top: 15%; }
    input:focus ~ label,
    input.has-content ~ label,
    textarea:focus ~ label,
    textarea.has-content ~ label,
    label.small {
        top: 1.2rem;
        font-size: 1.2rem;
        transition: all .15s; } }

.newsletter {
    display: flex;
    .form-group {
        margin-bottom: 0;
        input {
            background: $light;
            border: none;
            border-radius: 10px 0 0 10px; } }
    input[type="submit"] {
        display: none;
        & ~ label {
            width: 6rem;
            margin-left: -0.5rem;
            z-index: 1;
            display: flex;
            border-radius: 10px;
            align-items: center;
            justify-content: center;
            background: $primary;
            margin-bottom: 0;
            border: none;
            cursor: pointer;
            transition: all .15s;
            &:hover {
                background: darken($primary, 10%);
                transition: all .15s; }
            img {
                width: 20px; } } } }
