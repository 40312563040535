.success-page {
  margin-top: 0rem; }
@media screen and ( max-width: 1024px ) {
  padding-left: 2rem; }
.title-success {
  width: 70%;
  @media screen and ( max-width: 992px ) {
    width: 100%;
    font-size: 3rem !important; } }
.bg-success-container {
  height: 400px;
  background-color: #F3F5F7;
  background-image: url('../img/illustrations/pic-success-page.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and ( max-width: 992px ) {
    height: 20vh; } }
.success-left-m {
  margin-left: 18rem;
  @media screen and ( max-width: 1024px ) {
    margin-left: 0rem; } }
