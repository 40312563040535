// If sticky header
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  z-index: 9999;
  border-bottom: 1px solid $light;
  nav.navbar .nav-item a.nav-link {
      // color: $black
      &:hover {
          color: lighten($black, 20%); } } }
.logo {
  height: 7rem;
  margin-top: 2rem;
  transition: all .1s; }

.sticky .logo {
  height: 6rem;
  margin-top: 0;
  transition: all .3s; }
.sticky .secondary-line {
  bottom: 0;
  height: 0;
  overflow: hidden;
  transition: all .3s; }
.sticky .nav-container {
  padding-top: 4rem;
  transition: .3s; }
/* Reset the gap */
.sticky + section {
  margin-top: 0rem !important;
  @media screen and (max-width: 567px) {
    margin-top: 12rem; } }
.sticky + section.hero.inner,
.sticky + #offices,
.sticky + .success-page,
.sticky + .pd-top {
  margin-top: $header-height !important; }

// *************************

header + section {
  margin-top: -$header-height;
  padding-top: $header-height;

  header + section {
    margin-top: 0;
    padding-top: 0; }
  .sticky.header {
    height: auto !important; } }

.has-dropdown {
  position: relative;
  z-index: 99; }
.custom-dropdown {
  display: none;
  width: 70rem;
  height: 30rem;
  position: absolute;
  top: 2rem;
  right: -15rem;
  padding-top: 2rem;
  z-index: 999;
  .menu {
    width: 50%;
    padding: 2rem;
    background: $white;
    border-radius: 0 0 0 15px;
    ul {
      padding: 0;
      li {
        margin-bottom: 1rem;
        a {
          color: $light; } } } }
  a {
    font-size: 14px;
    color: $black !important; }
  .dropdown-banner {
    border-radius: 0 0 15px 0;
    width: 50%;
    background-image: url('../img/images/man-pointing-laptop-computer.png');
    background-repeat: no-repeat; } }

header {
  position: relative;
  display: flex !important;
  align-items: center;
  height: $header-height;
  z-index: 99999;
  background-color: $white;
  @media screen and (max-width: 992px) {
    height: auto;
    #navbar-bar, .languages {
      margin-top: 2rem; }
    .navbar-brand {
      margin-right: 2rem; } }

  nav {
    ul.navbar-nav {
      @media screen and (max-width: 992px) {
        display: block; }
      .dropdown-menu {
        border: none;
        .dropdown-item {
          color: rgba(0, 0, 0, 0.5); } } }
    .nav-item,
    .additional {
      margin: 1rem 1.4rem;
      a.nav-link {
        color: $white;
        font-weight: 600;
        &:after {
          transition: all .3s; } }

      &.has-dropdown:hover .custom-dropdown {
          display: flex; } } }
  .languages {
    margin-left: 1rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    a {
      width: 3.5rem;
      opacity: 0.8;
      &:hover {
        opacity: 1; } }
    .selected {
      font-weight: bold;
      i {
        color: $red;
        font-size: 1.2rem;
        margin-left: 0.2rem;
        transition: all .3s;
        &.rotate {
          transition: all .3s; } } } } }
.options {
  position: absolute;
  top: 2.1rem;
  right: 0rem;
  display: none;
  margin: 0;
  padding: 0;
  background: white;
  a {
    text-decoration: none !important;
    transition: all .3s;
    &:hover {
      transition: all .3s; } }
  &.show {
    display: block; } }
.navbar-brand {
  z-index: 999; }

.top-line {
  position: absolute;
  transition: all .1s;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-color: #F3F5F7;
  .top-line-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    .d-flex {
      margin: 0 -2rem; }
    @media screen and (max-width: 992px) {
      padding: 1rem 3rem !important; }
    @media screen and (max-width: 356px) {
      padding: 1rem !important; }
    i {
      font-size: 2.4rem; }
    .social-media-header {
      height: 2rem; } } }
.nav-container {
  transition: all .1s;
  padding: 6rem 0 1.2rem 0 !important;
  .navbar {
    padding: 0;
    margin: 0 -2rem;
    @media screen and (max-width: 992px) {
      margin: unset; } }
  @media screen and (max-width: 992px) {
    padding: 3rem 1.5rem 0 1.5rem !important; } }


.secondary-line {
  position: absolute;
  right: 25%;
  height: 4rem;
  bottom: -4rem;
  line-height: 4rem;
  z-index: 10;
  padding: 0 2rem;
  background: $black;
  transition: all .1s;
  span {
    margin-right: 2rem;
    font-weight: lighter;
    color: $white; }
  img {
    width: 13rem; } }

// hamburger menu animation
.navbar-light .navbar-toggler {
  border: none !important; }
.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  cursor: pointer;
  transition: all .5s ease-in-out; }
.menu-btn__burger {
  width: 30px;
  height: 4px;
  background: $primary;
  transition: all .1s ease-in-out; }
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  left: 0;
  width: 30px;
  height: 4px;
  background: $primary;
  transition: all .1s ease-in-out; }
.menu-btn__burger::before {
  transform: translateY(-8px); }
.menu-btn__burger::after {
  transform: translateY(-8px); }
/* animation */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none; }
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px); }
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px); }
