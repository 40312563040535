@mixin button {
    display: inline-flex !important;
    padding: 1.3rem 4rem !important;
    transition: all .3s;
    border: 1px solid $primary;
    align-items: center;
    border-radius: 10px;
    &:focus {
        background: darken($primary, 10%);
        outline: none !important; }
    @media screen and (max-width: 1024px) {
        &:not(:hover) {
            padding: 1.3rem 2rem !important; } }
    @media screen and (max-width: 992px) {
        &:not(:hover) {
            margin-top: 2rem; } } }

button {
    &:focus {
        border: 1px darken($black, 30%) solid; } }

.button-primary, {
    @include button;
    background: $primary;
    color: $white !important;
    &:after {
        content: url('../img/elements/white-arrow.svg');
        display: inline-block;
        width: 0rem;
        overflow: hidden;
        text-align: right;
        transition: all .3s;
        line-height: 0; }
    &:hover {
        background: darken($primary, 10%);
        transition: all .3s;
        text-decoration: none;
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
        &:after {
            width: 3rem;
            transition: all .3s; } } }

.button-primary-white {
    @include button;
    background: $white;
    text-decoration: none !important;
    color: $primary !important;
    &:after {
        content: url('../img/elements/red-arrow-small.svg');
        display: inline-block;
        width: 0rem;
        overflow: hidden;
        text-align: right;
        transition: all .3s;
        line-height: 0; }
    &:hover {
        background: darken($white, 10%);
        transition: all .3s;
        text-decoration: none;
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
        &:after {
            width: 3rem;
            transition: all .3s; } }
    @media screen and (max-width: 992px) {
        &:not(:hover) {
            margin-top: 0; } } }

.button-primary-trasparent {
    display: inline-flex;
    background: $primary;
    align-items: center;
    &:after {
        content: url('../img/elements/white-arrow.svg');
        display: inline-block;
        width: 0rem;
        overflow: hidden;
        text-align: right;
        transition: all .3s;
        line-height: 0; }
    &:hover {
        transition: all .3s;
        text-decoration: none;
        &:after {
            width: 2.5rem;
            transition: all .3s; } } }

.element-arrow {
    width: 0;
    text-align: right;
    display: inline-block;
    overflow: hidden;
    transition: all .3s; }

.button-white {
    @include button;
    background: $white;
    color: $black !important;
    @media screen and (max-width: 356px) {
        padding: 1.3rem 3rem !important; }
    &:hover {
        background: darken($white, 10%);
        transition: all .1s;
        text-decoration: none; } }
.button-yellow {
    @include button;
    background: $yellow;
    color: $black !important;
    border-color: $yellow;
    &:hover,
    &:focus {
        background: $yellow;
        text-decoration: none;
        transition: all .1s; }
    &:after {
        content: url('../img/elements/arrow.svg'); } }
.button-outlined {
    @include button;
    background: transparent;
    color: $primary;
    border: 1px solid $primary;
    margin-bottom: 1.5rem;
    &:hover {
        background: $primary;
        color: $white;
        transition: all .1s;
        text-decoration: none; } }

.button-link-dashed {
    border-bottom: 1px dashed $primary;
    &:hover {
        text-decoration: none;
        border-bottom: 1px solid $primary; } }
