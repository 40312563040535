// Colors
$red: #D12128;
$yellow: #FFDE02;

$white: #FFFFFF;
$light: #F3F5F7;
$grey: #363636;
$grey-light: #6A6A6A;
$black: #000000;

$font-color: $grey-light;
$primary: $red;
$secondary: $yellow;

// Sizes
$size-1: 5.5rem;
$size-2: 4.5rem;
$size-3: 3.5rem;
$size-4: 3rem;
$size-5: 2rem;
$size-6: 1.6rem;
$size-7: 1.4rem;

$font-size: $size-6;

$header-height: 12rem;

// Overriding bootstrap default headings
$h1-font-size: $size-1;
$h2-font-size: $size-2;
$h3-font-size: $size-3;
$h4-font-size: $size-4;
$h5-font-size: $size-5;
$h6-font-size: $size-6;

// Fonts
$font-primary: "Ubuntu", sans-serif;
